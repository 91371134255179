import React, { createContext, useContext, useState, useEffect } from "react";
import { POST } from "api/index";

interface PointsContextType {
  totalPoints: number;
  updateTotalPoints: () => Promise<void>;
  addPoints: (points: number) => void;
}

const PointsContext = createContext<PointsContextType | undefined>(undefined);

export const PointsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [totalPoints, setTotalPoints] = useState<number>(0);

  // Punkte beim Laden abrufen
  useEffect(() => {
    updateTotalPoints();
  }, []);

  // Holt den aktuellen Punktestand aus der API
  const updateTotalPoints = async () => {
    const body = new URLSearchParams();
    body.append("userToken", localStorage.getItem("currentUserToken") || "");

    try {
      const response = await POST("getTotalPoints", body);
      if (response && response.status === 200) {
        //console.log("Updated Total Points:", response.total_points);
        setTotalPoints(response.total_points);
      } else {
        console.error("Failed to fetch total points:", response);
      }
    } catch (error) {
      console.error("Error fetching total points:", error);
    }
  };

  // **Direkte Punkte-Aktualisierung im UI**
  const addPoints = (points: number) => {
    setTotalPoints((prev) => prev + points);
  };

  return (
    <PointsContext.Provider value={{ totalPoints, updateTotalPoints, addPoints }}>
      {children}
    </PointsContext.Provider>
  );
};

export const usePoints = () => {
  const context = useContext(PointsContext);
  if (!context) {
    throw new Error("usePoints must be used within a PointsProvider");
  }
  return context;
};
