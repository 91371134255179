import React, { useContext } from 'react';
import { Box, Button, IconButton, Rating, Typography, alpha, styled} from '@mui/material';
import { SelectedSkillContext, TextSizeContext } from 'data/index';
import ImageComponent from 'ui/components/shared/ImageComponent';
import CloseIcon from '@mui/icons-material/Close';  
import { useTranslation } from 'react-i18next';
import { MobileWidth, TextSizes } from 'entities/constants';
import WithBookSkillData from 'ui/containers/library/BookSkillContainer';
import { BookingCardPropType } from 'entities/interfaces';
import useScreenSize from 'hooks/ScreenSize';
import { useNavigate } from 'react-router-dom'; 

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? alpha(theme.palette.common.black, 0.1) : alpha(theme.palette.common.white, 0.6),
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '100vh',
  zIndex: 9999,
  display: 'flex',
  justifyContent: 'flex-end',
}));

const PopUp = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : '#fff',
  width: '33.125rem',
  maxWidth: '100%',
  height: '100%',
  borderRadius: '0.75rem 0px 0px 0.75rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  height: '5rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '0.75rem',
}));

const SkillBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flexGrow: '1',
  backgroundColor: theme.palette.grey[300],
  gap: '1rem',
  overflowY: 'hidden',
}));

const ScrollBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flexGrow: '1',
  padding: '0rem 2rem 0rem 2rem',
  gap: '1rem',
  overflowY: 'scroll',
  "&::-webkit-scrollbar": {
    width: "0.4rem",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
  },
}));

const WhiteText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white, 
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '1rem',
  margin: '1rem',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  width: '8rem',
  borderRadius: '3.5rem',
  textTransform: 'none',
  fontWeight: 700,
}));

const BookSkillCard = ({ bookCourse }: BookingCardPropType) => {
  const { t } = useTranslation();
  const { state: selectedSkill, updateContext: setSelectedSkill } = useContext(SelectedSkillContext);
  const { state: textSize } = useContext(TextSizeContext);
  const { width } = useScreenSize();
  const navigate = useNavigate();

  const handleBooking = async () => {
    if (selectedSkill) {
      const resp = await bookCourse(selectedSkill.id);
      if (resp.status === 200) {
        setSelectedSkill(undefined);
      }
    }
  };

  const handleStartCourse = () => {
    if (selectedSkill && selectedSkill.id) {
      navigate(`/courses/${selectedSkill.id}`);
    } else {
        console.error("⚠️ Kein Kurs ausgewählt oder ID fehlt!");
    }
  };

  if (!selectedSkill) return null;

  return (
    <Wrapper>
      <Box onClick={() => setSelectedSkill(undefined)} sx={{ flexGrow: '1', height: '100%' }} />
      <PopUp>
        <HeaderBox padding={`0.25rem ${width <= MobileWidth ? 2 : 1.25}rem`}
          flexDirection={width <= MobileWidth ? 'row-reverse' : 'row'}
          justifyContent={width <= MobileWidth ? 'space-between' : 'flex-start'}
        >
          {/* Schwarzes "X" zum Schließen */}
          <IconButton onClick={() => setSelectedSkill(undefined)}>
            <CloseIcon fontSize="large" sx={{ color: 'black' }} />
          </IconButton>
          <WhiteText fontSize={TextSizes[textSize].title3} fontWeight={700}>{t('Your Learning Takeaways')}</WhiteText>
        </HeaderBox>
        
        {/* Kursbild */}
        <ImageComponent src={selectedSkill.image} alt="skill image" width="100%" height={width <= MobileWidth ? '13rem' : '18.75rem'} objectFit />

        <SkillBox>
          <Box sx={{ padding: '2rem 2rem 0rem 2rem' }}>
            <WhiteText fontSize={TextSizes[textSize].title1} fontWeight={700}>{selectedSkill.title}</WhiteText>
            <WhiteText fontSize={TextSizes[textSize].subhead}>{selectedSkill.subtitle}</WhiteText>
            
            {/* Bewertung */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '0.5rem' }}>
              <Rating name="half-rating" value={selectedSkill.ratings} precision={0.1} readOnly />
              <WhiteText fontSize={TextSizes[textSize].title3} fontWeight={700}>{selectedSkill.ratings.toFixed(1)}</WhiteText>
              <WhiteText fontSize={TextSizes[textSize].footnote}>{`(${selectedSkill.ratingsCount.toLocaleString().replace(',', '.')})`}</WhiteText>
            </Box>
          </Box>

          {/* Kursbeschreibung */}
          <ScrollBox>
            <WhiteText fontSize={TextSizes[textSize].subhead}>{decodeURIComponent(selectedSkill.description)}</WhiteText>

            {/* Kapitelübersicht */}
            <Box>
              <WhiteText fontSize={TextSizes[textSize].title3} fontWeight={700}>{t('Chapter overview')}:</WhiteText>
              <Box sx={{ gap: '0.25rem', display: 'flex', flexDirection: 'column' }}>
                {selectedSkill.chapters.map((name, index) => (
                  <WhiteText key={index} fontSize={TextSizes[textSize].subhead}>
                    {index + 1}. {name}
                  </WhiteText>
                ))}
              </Box>
            </Box>
          </ScrollBox>

          <ButtonContainer>
    <ActionButton 
        variant="contained" 
        color="primary" 
        onClick={handleBooking}
        sx={{ borderRadius: '10px', minWidth: "150px"  }} // Weniger abgerundete Ecken
    >
        {t('Jetzt starten')}
    </ActionButton>
    <ActionButton 
        variant="outlined" 
        color="secondary" 
        onClick={handleBooking}
        sx={{ borderRadius: '10px', minWidth: "150px" }} // Auch für den zweiten Button
    >
        {t('Später')}
    </ActionButton>
</ButtonContainer>
        </SkillBox>
      </PopUp>
    </Wrapper>
  );
};

export default WithBookSkillData(BookSkillCard);
