import React, { useCallback, useEffect, useState } from 'react';
import { POST } from 'api/index';
import { ChatResponseInterface, ParagraphInterface, UserInputInterface } from 'entities/interfaces';
import ChatComponent from 'ui/components/chat/ChatComponent';
import useAlert from 'hooks/AlertHook';
import { fetchChatGPTResponse } from 'api/chatGPT';
import { usePoints } from "context/PointsContext";


const SkillsChatWrapper = ({ active, updateProgress }: any) => {
    const { setAlert } = useAlert();
    const token = window.localStorage.getItem("currentUserToken");
    const [nodesData, setNodesData] = useState<ParagraphInterface[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [calledNodes, setCalledNodes] = useState<number[]>([]);
    const [chapter, setChapter] = useState<{
        id: string
        title: string
        num: number,
        isBookmarked: boolean,
        isLastSection: boolean,
    }>();
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [typingAllowed, setTypingAllowed] = useState(false);
    const [processedChatMessages, setProcessedChatMessages] = useState<Set<string>>(new Set());
    const current = { chapter: '', section: '' };
    const [isWaitingForInput, setIsWaitingForInput] = useState(false);
    const [isSubmitted, setIsSubmitted]= useState(false);
    const [nodesProgress, setNodesProgress] = useState([]);
    const [ lastChatNodeOutputs, setLastChatNodeOutputs] = useState<string | null>(null);
    const { addPoints, updateTotalPoints } = usePoints();
    const [chatQueue, setChatQueue] = useState<ParagraphInterface[]>([]);
    const [currentChatNode, setCurrentChatNode] = useState<ParagraphInterface | null>(null);
    const [clickedIndexes, setClickedIndexes] = useState<Set<number>>(() => {
        const savedIndexes = localStorage.getItem('clickedIndexes');
        return savedIndexes ? new Set(JSON.parse(savedIndexes)) : new Set();
    });

    /*

    useEffect(() => {
        const fetchUnprocessedChats = async () => {
            const unprocessedChats = [];
    
            for (const node of nodesData) {
                if (node.type === 'chat' && !processedChatMessages.has(String(node.id))) {
                    const hasHistory = await loadChatProgress(node.id);
                    if (!hasHistory) {
                        unprocessedChats.push(node);
                    }
                }
            }
    
            setChatQueue(unprocessedChats);
        };
    
        if (nodesData.length > 0) {
            fetchUnprocessedChats();
        }
    }, [nodesData]);
    
    
    */
    useEffect(() => {
        if (chatQueue.length > 0) {
            const nextChat = chatQueue[0];
            setProcessedChatMessages((prev) => new Set(prev).add(String(nextChat.id)));
            setIsWaitingForInput(true);
    
            // Entferne den aktuellen Chat aus der Queue
            setChatQueue((queue) => queue.slice(1));
        }
    }, [chatQueue]);
    
    

useEffect(() => {
    const unprocessedChatMessages = nodesData.filter(
        (node) => node.type === 'chat' && !processedChatMessages.has(String(node.id))
    );

    if (unprocessedChatMessages.length > 0) {
        const nextChatMessage = unprocessedChatMessages[0]; // Nimm den ersten unverarbeiteten Chat-Knoten
        setCurrentChatNode(nextChatMessage); // Setze den aktuellen Knoten
        setTypingAllowed(true);
        setProcessedChatMessages((prev) => new Set(prev).add(String(nextChatMessage.id)));
        setIsWaitingForInput(true);
    }
}, [nodesData]);
/*
useEffect(() => {
    if (currentChatNode) {
        console.log("Prüfe gespeicherten Chat für Knoten:", currentChatNode.id);
        
        loadChatProgress(currentChatNode.id).then((exists) => {
            if (!exists) {
                setTypingAllowed(true); // Nur erlauben, wenn kein gespeicherter Chat existiert
            }
        });
        
    }
}, [currentChatNode]);


*/

    const formatBoldText = (text: string) => {
        return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>'); 
    };
  
    /*
    const handleUserInput = async (userInput: string) => {
        if (!currentChatNode) {
            console.error(" Kein aktueller Chat-Knoten gefunden.");
            return;
        }
    
        setIsLoading(true);
    
        const newUserMessage: UserInputInterface = {
            id: currentChatNode.id,
            content: userInput,
            type: "userInput",
            isLeft: false,
            outputs: "",
            prompt: "",
            prompt_type: "",
            section_id: 0,
            settings: "",
            status: "",
            title: "",
        };
    
        setNodesData((prevMessages) => {
            const currentChatIndex = prevMessages.findIndex(msg => msg.id === currentChatNode.id);
    
            if (currentChatIndex !== -1) {
                return [
                    ...prevMessages.slice(0, currentChatIndex + 1),
                    newUserMessage,
                    ...prevMessages.slice(currentChatIndex + 1),
                ];
            } else {
                console.warn("❌ Chat-Knoten nicht gefunden, füge Nachricht ans Ende hinzu.");
                return [...prevMessages, newUserMessage];
            }
        });
    
        try {
            const response = await fetchChatGPTResponse(
                currentChatNode.prompt || '',
                userInput
            );
            const formattedResponse = formatBoldText(response);
    
            const newBotMessage: ChatResponseInterface = {
                id: currentChatNode.id,
                content: formattedResponse,
                type: "response",
                isLeft: true,
                outputs: "",
                prompt: "",
                prompt_type: "",
                section_id: 0,
                settings: "",
                status: "",
                title: "",
            };
    
            setNodesData((prevMessages) => {
                const updatedMessages = [...prevMessages];
                const userMessageIndex = updatedMessages.findIndex(
                    (msg) => msg.content === userInput && msg.type === 'userInput'
                );
    
                if (userMessageIndex !== -1) {
                    updatedMessages.splice(userMessageIndex + 1, 0, newBotMessage);
                }
    
                return updatedMessages;
            });
    
            saveChatProgress(currentChatNode.id, userInput, formattedResponse);
        } catch (error) {
            console.error("❌ Fehler beim Aufruf der API:", error);
        }
    
        setIsLoading(false);
    };
    */
    const handleUserInput = async (userInput: string) => {

        const currentChatNode = nodesData.find((node) => node.type === 'chat' && !(node as any).processed);
    
        if (!currentChatNode) {
            console.error("Kein aktueller Chat-Knoten verfügbar.");
            return;
        }
    
        // Markiere den aktuellen Chat-Knoten als verarbeitet (optional, falls nötig)
        setNodesData((prevMessages) =>
            prevMessages.map((node) =>
                node.id === currentChatNode.id ? { ...node, processed: true } : node
            )
        );
        setIsLoading(true);
    
        // Erstelle eine neue Benutzerantwort
        const newUserMessage = {
            content: userInput,
            type: 'userInput',
            isLeft: false,
        } as UserInputInterface;
    
        // Füge die Benutzerantwort hinter dem aktuellen Chat-Knoten ein
        setNodesData((prevMessages) => {
            const currentChatIndex = prevMessages.findIndex(
                (msg) => msg.id === currentChatNode.id
            );
    
            if (currentChatIndex === -1) {
                console.error("Aktueller Chat-Knoten nicht in nodesData gefunden.");
                return prevMessages;
            }
    
            const updatedMessages = [
                ...prevMessages.slice(0, currentChatIndex + 1),
                newUserMessage,
                ...prevMessages.slice(currentChatIndex + 1),
            ];
            return updatedMessages;
        });
    
        // Bereite die Daten für die API-Anfrage vor
        const requestData = {
            context: currentChatNode.content,
            prompt: currentChatNode.prompt || '',
            userInput: userInput,
        };
    
        try {
            // Hole die Antwort von der API
            const response = await fetchChatGPTResponse(
                //requestData.context,
                requestData.prompt,
                requestData.userInput
            );
            const formattedResponse = formatBoldText(response);
    
            // Erstelle die Bot-Antwort
            const newBotMessage = {
                content: formattedResponse,
                type: 'response',
                isLeft: true,
            } as ChatResponseInterface;
    
            setNodesData((prevMessages) => {
                const updatedMessages = [...prevMessages];
                const userMessageIndex = updatedMessages.findIndex(
                    (msg) => msg.content === newUserMessage.content && msg.type === 'userInput'
                    
                );
    
                if (userMessageIndex !== -1) {
                    updatedMessages.splice(userMessageIndex + 1, 0, newBotMessage);
                }
    
                return updatedMessages;
            });
    
            // Aktualisiere die Outputs des aktuellen Chat-Knotens
            if (currentChatNode.outputs) {
                setLastChatNodeOutputs(currentChatNode.outputs);
                saveChatProgress(currentChatNode.id, userInput, newBotMessage.content)
            }
        } catch (error) {
            console.error("Fehler beim Aufruf der API:", error);
        }
        setIsLoading(false);
        
    };




    const decodeMessage = (message: string) => {
        const userName = localStorage.getItem('currentUserName')?.split(' ')[0] || '';
        const decodedMessage = decodeURIComponent(message);
        return decodedMessage.replaceAll('@user.name', userName);
      }


    // Parsing JSON-Object for multiple choice components
    function parseContent(node: ParagraphInterface) {
        if (node.type === 'mc' || node.type === 'io' && node.content.startsWith('%7B%0A%20%20%22type%22%3A%20%22mc')) {
            try {
                const decodedContent = JSON.parse(decodeMessage(decodeURIComponent(node.content)));
                if (node.title === 'Single Choice') {
                    decodedContent.isSingleChoice = true;
                } else {
                    decodedContent.isSingleChoice = decodedContent.options?.length === 2; 
                }
                return { ...node, content: decodedContent, requiresInput: true };
            } catch (error) {
                console.error("Fehler beim Parsen des Inhalts: ", error);
            }
        }
        if (node.type === 'chat') {
            try {
                const encodedStringContent = node.content;
                const decodedStringContent = decodeURIComponent(encodedStringContent);
                
                const encodedStringPrompt = node.prompt;
                const decodedStringPrompt = decodeURIComponent(encodedStringPrompt);

                return { ...node, content: decodedStringContent, prompt: decodedStringPrompt, requiresInput: true };
            } catch (error) {
                console.error("Fehler beim Parsen des Inhalts: ", error);
            }
            return node;
        }
        if (node.type === 'io' && (node.title === "Drag-and-Drop" || node.title === "DnD" || node.title === "Drag-And-Drop" || node.title === "Drag-and-Drop-Multiple")) {
            if (node.title === 'Drag-and-Drop-Multiple'){}
                else{node.title = "Drag-and-Drop"}
            try {
                const decodedContent = decodeMessage(decodeURIComponent(node.content));
                return {...node, content: decodedContent, requiresInput: true};
            } catch (error) {
                console.error("Fehler beim Parsen des Inhalts: ", error);
            }
            return node;
        }

        return node;
    }
     
    const getChatData = async (course_id: string) => {
        const chaptersBody = new URLSearchParams();
        chaptersBody.append('userToken', token || '');
        chaptersBody.append('option', course_id);
    
        const { result: chapters } = await POST('getChapters', chaptersBody);
    
        if (chapters) {
            const progressResult = await Promise.all(
                chapters.map(async (chapter: any) => {
                    const progressBody = new URLSearchParams();
                    progressBody.append('userToken', token || '');
                    progressBody.append('option', chapter.id.toString());
                    progressBody.append('content', '2');
                    const { result: progress } = await POST('getUserProgress', progressBody);
    
                    return parseInt(progress);
                })
            );
    
            let foundSection = false;

            for (let i = 0; i < chapters.length; i++) {
                if (progressResult[i] === 0 || progressResult[i] === 1) {
                    current.chapter = chapters[i].id;

                    
                    const isLastChapter = chapters[i] && i === chapters.length - 1;
                    const sectionsBody = new URLSearchParams();
                    sectionsBody.append('option', current.chapter);
                    const { result: sections } = await POST('getSections', sectionsBody);
    
                    if (sections) {
                        const sectionsProgressResult = await Promise.all(
                            sections.map(async (section: any) => {
                                const progressBody = new URLSearchParams();
                                progressBody.append('userToken', token || '');
                                progressBody.append('option', section.id.toString());
                                progressBody.append('content', '3');
                                const { result: progress } = await POST('getUserProgress', progressBody);
    
                                return parseInt(progress);
                            })
                        );
    
                        for (let j = 0; j < sections.length; j++) {
                            if (sectionsProgressResult[j] === 0 || sectionsProgressResult[j] === 1) {
                                current.section = sections[j].id;
                                const isLastSection = sections[j] && sections[j].id === sections[sections.length - 1]?.id;
                                const endOfCourse = (isLastChapter&&isLastSection) 
                                setChapter({ id: chapters[i].id, title: chapters[i].title, num: chapters[i].ordering, isBookmarked: chapters[i].bookmark === '1', isLastSection: endOfCourse, });
                                foundSection = true;

                                break;
                            }
                        }
                    }
    
                    if (foundSection) {
                        break;
                    }
                }
            }
    
            // Exception handling for missing user history
            if (!foundSection || !current.section) {
                console.warn('No active section found, handling as exception.');
    
                // Select first chapter when there is no user progress
                const sectionsBody = new URLSearchParams();
                sectionsBody.append('option', current.chapter);
                const { result: sections } = await POST('getSections', sectionsBody);
    
                if (sections && sections.length > 0) {
                    current.section = sections[0].id;
                    current.chapter = chapters[0].id;
                    const isLastSection = sections[0].id === sections[sections.length - 1].id; 
                    const isLastChapter = chapters.length > 0 && chapters[0]?.id === chapters[chapters.length - 1]?.id;
                    const endOfCourse = (isLastChapter&&isLastSection) 
                    // Set chapter as finished
                    const chapterProgressBody = new URLSearchParams();
                    chapterProgressBody.append('userToken', token || '');
                    chapterProgressBody.append('option', current.chapter.toString());
                    chapterProgressBody.append('content', '2');
                    await POST('setUserProgress', chapterProgressBody);
    
                    const sectionProgressBody = new URLSearchParams();
                    sectionProgressBody.append('userToken', token || '');
                    sectionProgressBody.append('option', current.section.toString());
                    sectionProgressBody.append('content', '3');
                    await POST('setUserProgress', sectionProgressBody);
    
                    setChapter({
                        id: chapters[0].id,
                        title: `${chapters[0].title}`,
                        num: chapters[0].ordering,
                        isBookmarked: chapters[0].bookmark === '1',
                        isLastSection: endOfCourse,
 
                    });
                } else {
                    console.error('No sections available in the first chapter.');
                    return;
                }
            }
    
            const paragraphsData = await getSectionHistoryParagraphs(current.section);
    
            if (paragraphsData.length === 0) {
                const paragraphsBody = new URLSearchParams();
                paragraphsBody.append('option', current.section);
                const { result: paragraphs } = await POST('getParagraphs', paragraphsBody);
    
                if (paragraphs) {
                    const start = paragraphs.find((item: ParagraphInterface) => item.type === 'start');
                    if (start) {
                        paragraphsData.push(start);
                        await markParagraph(start.id);
                    }
                }
            }
            if (!Array.isArray(paragraphsData)) {
                console.error("paragraphsData is not a valid array:", paragraphsData);
                setNodesData([]);
                return;
            }
            
            const validParagraphs = paragraphsData.filter((node) => node && typeof node === 'object' && node.type);
            
            if (validParagraphs.length !== paragraphsData.length) {
                console.warn(`Filtered out invalid nodes: ${paragraphsData.length - validParagraphs.length}`);
            }
            
            const parsedNodesData = validParagraphs.map(parseContent);
            setNodesData(parsedNodesData);

           /* const fetchStoredChats = async () => {
                console.log("🔄 Lade gespeicherte Chat-Nachrichten für alle Chat-Knoten...");
                
                const updatedNodes = await Promise.all(parsedNodesData.map(async (node) => {
                    if (node.type === 'chat') {
                        const chatHistory = await loadChatProgress(node.id);
                        

                        if (chatHistory && typeof chatHistory === "object" && "user_response" in chatHistory && "gpt_response" in chatHistory) {
                            console.log(`✅ Chat-Daten für Knoten ${node.id} gefunden!`);
            
                            const { user_response, gpt_response } = chatHistory;
            
                            const savedUserMessage: UserInputInterface = {
                                id: 0,
                                content: user_response || "⚠️ Fehler: Keine Nutzereingabe gespeichert",
                                type: "userInput",
                                isLeft: false,
                                outputs: "",
                                prompt: "",
                                prompt_type: "",
                                section_id: node.section_id ?? 0,
                                settings: "",
                                status: "",
                                title: "",
                            };
            
                            const savedGPTMessage: ChatResponseInterface = {
                                id: 0,
                                content: gpt_response || "⚠️ Fehler: Keine GPT-Antwort gespeichert",
                                type: "response",
                                isLeft: true,
                                outputs: "",
                                prompt: "",
                                prompt_type: "",
                                section_id: node.section_id ?? 0,
                                settings: "",
                                status: "",
                                title: "",
                            };
                            return [node, savedUserMessage, savedGPTMessage]; 
                        }
                    }
                    return node;
                }));
            

                setNodesData(updatedNodes.flat());
            };
            
            
*/
            

        //console.log("NodesData: ",nodesData)
            if (nodesData.some((node) => node.type === 'chat')){
                setTypingAllowed(true);
            }
            setIsLoading(false);
        } else {
            console.error('No chapters available for the course.');
        }
    };
    
    const bookmarkChapter = async (id: string, value: number) => {
        const body = new URLSearchParams();
        if (token)
            body.append('userToken', token);
        body.append('option', id);
        body.append('content', value.toString());
        const response = await POST('setBookmark', body)
        if (response.status === 200) {
            chapter && setChapter({ ...chapter, isBookmarked: value === 1 })
        }
    }

    const clearProgress = async () => {
        const resetBody = new URLSearchParams();
        resetBody.append('userToken', token || '');
        resetBody.append('option', active.id);
        const response = await POST('clearUserProgress', resetBody);
        if (response.status === 200) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setIsLoading(true);
            setCalledNodes([]);
            setNodesData([]);
            setChapter(undefined);
    
            // Setze clickedIndexes zurück
            localStorage.removeItem('clickedIndexes'); // Entferne aus dem Local Storage
            setClickedIndexes(new Set()); // Setze Zustand zurück
    
            await getChatData(active.id);
            setAlert({ color: 'success', message: 'Course progress cleared!' });
        }
    };
    
    const getSectionHistoryParagraphs = async (sectionId: string) => {
        const historyBody = new URLSearchParams()
        historyBody.append('userToken', token || '')
        historyBody.append('option', sectionId)
        const historyResp = await POST('getSectionHistory', historyBody)
    
        let history = historyResp.content;
        const paragraphs: ParagraphInterface[] = await Promise.all(
            history.map(async (paragraphId: any) => {
                const body = new URLSearchParams();
                body.append('option', paragraphId.toString());
                const paragraphsResp = await POST('getParagraph', body);
                let paragraph = paragraphsResp.result;
                return paragraph;
            })
        );
                await Promise.all(
                    paragraphs.map(async (paragraph) => {
                        if (paragraph.title === "Multiple Choice" || paragraph.title === "Multiple-Choice" || paragraph.title === "Drag-and-Drop" || paragraph.title === "Drag-and-Drop-Multiple") {
                            const progress = await fetchNodeProgress(paragraph.id);
                            if (progress) {
                                const parsedObject = JSON.parse(progress);
                                //console.log("Fetched Data: ", parsedObject);
                                
                                    paragraph.progressState = parsedObject.progressState; 
                         
                            }
                        }
                    })
                );
                
        return paragraphs;
    };
    


    const fetchNodeProgress = async (nodeId: number) => {
        const requestBody = new URLSearchParams();
        requestBody.append('userToken', token || '');
        requestBody.append('option', nodeId.toString());
    
        try {
            const response = await POST('/getInteractiveProgress', requestBody);
    
            if (response && response.progress) {

                return JSON.stringify({
                    nodeId: response.option,
                    progressState: response.progress.state,  // Für MC
                    placedElements: response.progress.placedElements || {},  // Für DnD
                });
            } else {
                console.error('No progress found or error occurred:', response.error);
                return null;
            }
        } catch (error) {
            console.error('Error fetching progress:', error);
            return null;
        }
    };
    

const getNext = useCallback(async (outputs: string) => {
    setIsLoading(true);
    const [nextParagraphId, delay] = outputs.split('@');

    const body = new URLSearchParams();
    body.append('option', nextParagraphId);

    const startTime = Date.now();
    const paragraphsResp = await POST('getParagraph', body);
    const apiResponseTime = Date.now() - startTime;

    const remainingDelay = Math.max(parseInt(delay) * 1000 - apiResponseTime, 0);

    setTimeout(async () => {
        setIsLoading(false);
        const paragraph = paragraphsResp.result;
        const parsedParagraph = parseContent(paragraph);
        setNodesData(nodesData => [...nodesData, parsedParagraph]);

        await markParagraph(paragraph.id);
    }, remainingDelay);
}, []);





    const markParagraph = async (paragraphId: string) => {
        const body = new URLSearchParams();
        body.append('userToken', token || '');
        body.append('option', paragraphId);
        const response = await POST('markParagraph', body);
        updateTotalPoints();
    }

    const handleFeedback = async (sectionId: number, feedback: number) => {
        const body = new URLSearchParams();
        body.append('userToken', token || '');
        body.append('option', sectionId.toString());
        body.append('content', feedback.toString());
        await POST('setSectionFeedback', body)
        updateProgress()
        setIsLoading(true)
        setCalledNodes([])
        setNodesData([])
        setChapter(undefined)
        getChatData(active.id)
    }

    const handleNextChapter = async (sectionId: number) => {
        //const body = new URLSearchParams();
        //body.append('userToken', token || '');
        //body.append('option', sectionId.toString());
        //body.append('content', feedback.toString());
        //await POST('setSectionFeedback', body)
        updateProgress()
        setIsLoading(true)
        setCalledNodes([])
        setNodesData([])
        setChapter(undefined)
        getChatData(active.id)
    }


    const reportParagraph = async (paragraphId: string, content: string) => {
        const body = new URLSearchParams();
        body.append('userToken', token || '');
        body.append('option', paragraphId.toString());
        body.append('content', content.toString());
        await POST('setParagraphFeedback', body)
    }
    
    useEffect(() => {
        if (!active || !active.id) return;
    
        //console.log("🔄 Kurswechsel erkannt, lade neue Daten für:", active.id);
    
        setIsLoading(true);
        setCalledNodes([]);
        setNodesData([]);  // Setze NodesData zurück, um alte Nachrichten zu entfernen
        setChapter(undefined);
        getChatData(active.id).then(() => {
            //console.log("✅ getChatData abgeschlossen, NodesData neu geladen:", nodesData);
        });
    
    }, [active]);
    

  
    const handleSaveProgress = async (data: { placedElements?: Record<string, string[]>; selectedOptions?: string[]; points: number }) => {

        const progressBody = new URLSearchParams();
        progressBody.append('userToken', token || '');
        if(nodesData && nodesData.length > 0) {
            const last = nodesData[nodesData.length - 1]
            const nodeId = last.id
            progressBody.append('option', nodeId.toString());
        }
        else{progressBody.append('option', "unknown");}

        const chaptersBody = new URLSearchParams();
        chaptersBody.append('userToken', token || '');
        chaptersBody.append('option', active.id);

        const content = {
            courseId: active.id, 
            type: data.placedElements ? 'dnd' : 'mc', // Überprüfung welcher Typ vorliegt
            achievedAt: new Date().toISOString(),
            points: data.points,
            placedElements: data.placedElements || {}, // Standardwert setzen
            selectedOptions: data.selectedOptions || [], // Standardwert setzen
        };
    


        //console.log("HandleSaveProgress in SKillschatwrapper: ", content)
        // Content als JSON-String anhängen
        try{
        progressBody.append('content', JSON.stringify(content));
        }
        catch(error){ console.log("Error parsing content: ",error)
        }
        try {
            const response = await POST('setInteractiveProgress', progressBody);
            updateTotalPoints();
            // Falls die Methode schon JSON zurückgibt, direkt verwenden
            const result = typeof response === "object" ? response : await response.json();

            if (result.status !== 200) {
                console.error("Fehler beim Speichern des Fortschritts:", result);
            } 
        } catch (error) {
            console.error("Netzwerkfehler:", error);
        }
    };


    const saveChatProgress = async (nodeId: number, userResponse: string, gptResponse: string) => {
        const body = new URLSearchParams();
        body.append('userToken', localStorage.getItem('currentUserToken') || '');
        body.append('nodeId', nodeId.toString());
        body.append('userResponse', userResponse);
        body.append('gptResponse', gptResponse);
    
        try {
            //console.log("Sende Chat-Fortschritt für Node-ID:", nodeId);
            const response = await POST('setChatProgress', body);
            
            if (!response || response.status !== 200) {
                console.error("API-Fehler beim Speichern des Chatfortschritts:", response);
            } 
        } catch (error) {
            console.error("Netzwerkfehler beim Speichern des Chatfortschritts:", error);
        }
    };
    /*
    const loadChatProgress = async (nodeId: number) => {
        const body = new URLSearchParams();
        body.append('userToken', localStorage.getItem('currentUserToken') || '');
        body.append('nodeId', nodeId.toString());
    
        try {
            const response = await POST('getChatProgress', body);
            console.log("🛠️ Geladene Chat-Daten:", response);
    
            if (response && response.status === 200 && response.chatHistory) {
                const { user_response, gpt_response } = response.chatHistory;
    
                const savedUserMessage: UserInputInterface = {
                    id: 0,
                    content: user_response,
                    type: "userInput",
                    isLeft: false,
                    outputs: "",
                    prompt: "",
                    prompt_type: "",
                    section_id: 0,
                    settings: "",
                    status: "",
                    title: "",
                };
    
                const savedGPTMessage: ChatResponseInterface = {
                    id: 0,
                    content: gpt_response,
                    type: "response",
                    isLeft: true,
                    outputs: "",
                    prompt: "",
                    prompt_type: "",
                    section_id: 0,
                    settings: "",
                    status: "",
                    title: "",
                };
    
                
                //TODO
                setNodesData((prevMessages) => {
                    const updatedMessages = [...prevMessages];
                    const chatNodeIndex = updatedMessages.findIndex(msg => msg.id === nodeId);
    
                    if (chatNodeIndex !== -1) {
                        console.log("✅ Chat-Knoten gefunden, füge Nachrichten direkt danach ein.");
                        updatedMessages.splice(chatNodeIndex + 1, 0, savedUserMessage, savedGPTMessage);
                    } else {
                        console.warn("⚠️ Chat-Knoten nicht gefunden, füge Nachrichten ans Ende hinzu.");
                        updatedMessages.push(savedUserMessage, savedGPTMessage);
                    }
                    return updatedMessages;
                });
    
                return response.chatHistory || null;
                ; // Zeigt an, dass eine Nachricht existiert
            }
    
        } catch (error) {
            console.error("🚨 Fehler beim Laden des Chatverlaufs:", error);
        }
        return null;
 // Falls keine Nachricht existiert
    };
    
    */
    
    useEffect(() => {
        if (nodesData && nodesData.length > 0) {
            const last = nodesData[nodesData.length - 1]
            if (last.requiresInput) {
                setIsWaitingForInput(true);
                return; 
            }
            setIsWaitingForInput(false);
            if (last.type !== 'next' && last.outputs && last.outputs.split(',').length === 1) {
                if (!calledNodes.includes(last.id)) {
                    setCalledNodes(calledNodes => [...calledNodes, last.id])
                    getNext(last.outputs);
                }
            }

            if(last.type=='response' && currentChatNode){
                getNext(currentChatNode.outputs)
            }
        }
       
    }, [calledNodes, getNext, nodesData, active]);



    useEffect(() => {
        // Clean up the timeout when the component unmounts
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);



    
    
    const handleSubmit = () => {
        setIsSubmitted(true);
        setIsWaitingForInput(false);

    
        if (nodesData && nodesData.length > 0) {
            const last = nodesData[nodesData.length - 1];
            
            if (last.type !== 'next' && last.outputs && last.outputs.split(',').length === 1) {
                if (!calledNodes.includes(last.id)) {
                    setCalledNodes((calledNodes) => [...calledNodes, last.id]);
    
                    // Verzögerung, um Feedback zuerst anzuzeigen
                    setTimeout(() => {
                        getNext(last.outputs);
                    }, 2000); // Wartezeit von 2 Sekunden
                }
            }
        }
    };
    
   
    useEffect(() => {
        setIsWaitingForInput(false);
        if (isSubmitted) {            
            setIsSubmitted(false)
        }
    }, [isSubmitted]);
    
    return (
        <>
            {active &&
                <ChatComponent
                    messagesData={nodesData}
                    date={new Date()}
                    sendDisabled
                    chapter={chapter}
                    bookmarkChapter={bookmarkChapter}
                    clearProgress={clearProgress}
                    handleNext={getNext}
                    isLoading={isLoading}
                    handleFeedback={handleFeedback}
                    handleNextChapter={handleNextChapter}
                    reportAllowed
                    reportParagraph={reportParagraph}
                    typingAllowed={typingAllowed}
                    onUserInput={handleUserInput}
                    onSubmit={handleSubmit}
                    setClickedIndexes={setClickedIndexes}
                    clickedIndexes={clickedIndexes}
                    handleSaveProgress={handleSaveProgress}

                />
            }
        </>
    );
};

export default SkillsChatWrapper;