import { config } from "../config";
import OpenAI from "openai";

const apiKey = config.OPENAI_API_KEY;
const model = config.OPENAI_MODEL || 'gpt-4o';

const openai = new OpenAI({
  apiKey: apiKey,
  dangerouslyAllowBrowser: true,
});

export const fetchChatGPTResponse = async (
  //context: string,
  prompt: string,
  userInput: string
  
): Promise<string> => {
  try {
    //console.log("context: ", context)
    console.log("Prompt: ",prompt)
    console.log("UserInput: ", userInput)
    const completion = await openai.chat.completions.create({
      model: model,
      messages: [
        { role: 'system', content: prompt },
        { role: 'user', content: `${userInput}` },
      ],
    });

    // Die Antwort aus der ersten Wahl extrahieren
    return completion.choices[0].message?.content || 'Keine Antwort erhalten';
  } catch (error) {
    console.error('Error fetching ChatGPT response:', error);
    throw new Error('Failed to fetch response from ChatGPT');
  }
};
