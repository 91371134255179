import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DialogContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? theme.palette.common.white : theme.palette.grey[900],
  padding: "2rem",
  borderRadius: "12px",
  textAlign: "center",
  boxShadow: theme.palette.mode === "light"
    ? "0 8px 16px rgba(0, 0, 0, 0.1)"
    : "0 8px 16px rgba(255, 255, 255, 0.1)",
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
  padding: "0.8rem 2rem",
  fontSize: "1rem",
  fontWeight: "bold",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(255, 0, 0, 0.3)",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.error.dark,
    transform: "scale(1.05)",
  },
  "&:active": {
    transform: "scale(0.98)",
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.grey[900],
  padding: "0.8rem 2rem",
  fontSize: "1rem",
  fontWeight: "bold",
  borderRadius: "8px",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.grey[400],
    transform: "scale(1.05)",
  },
  "&:active": {
    transform: "scale(0.98)",
  },
}));

export const LogoutDialog = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // ✅ Öffne das Dialog-Fenster automatisch, sobald die Komponente geladen wird.
  useEffect(() => {
    setOpen(true);
  }, []);

  const handleCloseDialog = () => {
    setOpen(false); // ✅ Dialog sanft schließen
    setTimeout(() => navigate("/settings"), 300); // ✅ Nach 300ms zu Settings zurückkehren
  };

  const handleLogout = () => {
    localStorage.removeItem("currentUserToken");
    localStorage.removeItem("currentUserName");
    navigate("/auth"); // ✅ Weiterleitung zur Login-Seite nach Logout
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "12px",
          minWidth: "400px",
          textAlign: "center",
        },
      }}
    >
      <DialogContainer>
        <DialogTitle id="logout-dialog-title" sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
          Abmelden?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description" sx={{ fontSize: "1rem", color: theme.palette.text.secondary }}>
            Möchtest du dich wirklich abmelden? Deine aktuellen Sitzungsdaten gehen verloren.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", gap: "1rem", marginBottom: "1rem" }}>
          <CancelButton onClick={handleCloseDialog}>Abbrechen</CancelButton>
          <LogoutButton onClick={handleLogout}>Ja, abmelden</LogoutButton>
        </DialogActions>
      </DialogContainer>
    </Dialog>
  );
};
