import React, { useContext } from 'react';
import { Box, Button, Rating, Typography, styled } from '@mui/material';
import ImageComponent from 'ui/components/shared/ImageComponent';
import { LibraryCourseInterface } from 'entities/interfaces';
import { SelectedSkillContext, TextSizeContext } from 'data/index';
import { TextSizes } from 'entities/constants';

const Wrapper = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],
  minHeight: '26rem',  // Erhöhte Mindesthöhe für bessere Darstellung
  width: '18rem',
  margin: '0.4rem',
  borderRadius: '0.75rem',
  textTransform: 'none',
  boxShadow: theme.palette.mode === 'light' ? '0px 4px 7px rgba(193, 193, 193, 0.25)' : '',
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '1.75rem',
  alignItems: 'flex-start',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
  },
}));

const DetailsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  textAlign: 'start',
  padding: '1rem',
  width: '100%',
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  width: '100%',
  whiteSpace: 'normal', // Erlaubt Zeilenumbruch
  overflowWrap: 'break-word', // Verhindert Überlauf
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2, // Begrenzung auf 2 Zeilen, falls zu lang
  textOverflow: 'ellipsis', // Falls der Text zu lang ist, wird er abgeschnitten
}));

const RatingsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
}));

const RatingsText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
}));

const RatingsCount = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.text.secondary,
}));

const LibrarySkillCard = ({ data }: { data: LibraryCourseInterface }) => {
  const { updateContext: setSelectedSkill } = useContext(SelectedSkillContext);
  const { state: textSize } = useContext(TextSizeContext);

  return (
    <Wrapper onClick={() => setSelectedSkill(data)}>
      <ImageComponent src={data.image} alt="image" width="100%" height="14rem" borderRadius="0.75rem 0.75rem 0 0" />
      <DetailsBox>
        <Label style={{ fontSize: TextSizes[textSize].title2 }}>{data.title}</Label>
        <RatingsBox>
          <Rating name="half-rating" value={data.ratings} precision={0.1} readOnly />
          <RatingsText style={{ fontSize: TextSizes[textSize].title3 }}>{data.ratings.toFixed(1)}</RatingsText>
          <RatingsCount style={{ fontSize: TextSizes[textSize].footnote }}>
            ({data.ratingsCount.toLocaleString().replace(',', '.')})
          </RatingsCount>
        </RatingsBox>
      </DetailsBox>
    </Wrapper>
  );
};

export default LibrarySkillCard;
