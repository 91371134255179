import { Box, styled, Typography, TextField, MenuItem, Select, InputLabel, FormControl, SelectChangeEvent  } from '@mui/material';
import React, { useContext, useState } from 'react';
import LibrarySkillCard from '../cards/LibrarySkillCard';
import BookSkillCard from '../cards/BookSkillCard';
import { SelectedSkillContext } from 'data/index';
import { LibraryPropType } from 'entities/interfaces';
import { useTranslation } from 'react-i18next';

// Styled Components
const Container = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: '2rem 1.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '1rem',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.5rem',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
}));

const CoursesGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
  gap: '1.5rem',
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: '1rem',
  borderRadius: '0.75rem',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  flex: 1,
  '.MuiOutlinedInput-root': {
    borderRadius: '0.75rem',
    backgroundColor: theme.palette.background.default,
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  minWidth: '12rem',
  '.MuiOutlinedInput-root': {
    borderRadius: '0.75rem',
    backgroundColor: theme.palette.background.default,
  },
}));

const Library = ({ courses }: LibraryPropType) => {
  const { state: selectedSkill } = useContext(SelectedSkillContext);
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('All');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategoryFilter(event.target.value as string);
  };

  const filteredCourses = courses.filter((course) => {
    const matchesSearch = course.title.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = categoryFilter === 'All' || (course.category && course.category === categoryFilter);
    return matchesSearch && matchesCategory;
  });

  return (
    <Container>
      {/* Header Section */}
      <Header>
        <Title>{t('Explore Skills and Courses')}</Title>
      </Header>

      {/* Filter Section */}
      <FilterContainer>
        <StyledTextField
          label={t('Search')}
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <FormControl variant="outlined">
          <InputLabel>{t('Category')}</InputLabel>
          <StyledSelect
            value={categoryFilter}
            onChange={(event) => handleCategoryChange(event as SelectChangeEvent<string>)}
            label={t('Category')}
          >
            <MenuItem value="All">{t('All')}</MenuItem>
            <MenuItem value="Creativity">{t('Kreativität')}</MenuItem>
            <MenuItem value="Communication">{t('Kommunikation')}</MenuItem>
            <MenuItem value="Conflict">{t('Konflikte')}</MenuItem>
            <MenuItem value="Agility">{t('Agiles Arbeiten')}</MenuItem>
            <MenuItem value="Stress">{t('Stress & Resilienz')}</MenuItem>
            <MenuItem value="Digital">{t('Digitale Skills')}</MenuItem>
          </StyledSelect>
        </FormControl>
      </FilterContainer>

      {/* Main Content Section */}
      <ContentWrapper>
        {/* Courses Section */}
        {filteredCourses.length > 0 ? (
          <Box>
            <Typography variant="h6" color="textSecondary">
              {t('All Courses')}
            </Typography>
            <CoursesGrid>
              {filteredCourses.map((course, index) => (
                <LibrarySkillCard
                  key={index}
                  data={{ ...course, ratings: 5, ratingsCount: 100 }}
                />
              ))}
            </CoursesGrid>
          </Box>
        ) : (
          <Typography variant="body1" color="textSecondary">
            {t('No courses found.')}
          </Typography>
        )}

        {/* Selected Skill Section */}
        {selectedSkill && (
          <Box>
            <Typography variant="h6" color="textSecondary">
              {t('Your Selected Skill')}
            </Typography>
            <BookSkillCard />
          </Box>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default Library;
