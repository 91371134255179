import ImageComponent from "ui/components/shared/ImageComponent"
import MySkillsIcon from 'assets/icons/Myskills.png'
import MySkillsActiveIcon from 'assets/icons/Myskills-active.png'
import SkillboardIcon from 'assets/icons/Skillboard.png'
import SkillboardActiveIcon from 'assets/icons/Skillboard-active.png'
import SkillaryIcon from 'assets/icons/Skillary.png'
import SkillaryActiveIcon from 'assets/icons/Skillary-active.png'
import SkillboxIcon from 'assets/icons/Skillbox.png'
import SkillboxActiveIcon from 'assets/icons/Skillbox-active.png'
import SkillcardIcon from 'assets/icons/Skillcards.png'
import SkillcardActiveIcon from 'assets/icons/Skillcards-active.png'
import Home from "ui/pages/home"
import Skills from "ui/pages/skills"
import Library from "ui/pages/library"
import { SelectedSkillContextProvider } from "data/index"
import Skillbox from "ui/pages/skillbox"
import Skillcards from "ui/pages/Skillcards/Skillcards"
import { RoutesInterface } from "./interfaces"
import SettingsIconD from 'assets/icons/Settings-desktop.png'
import SettingsIcon from 'assets/icons/Settings.png'
import SettingsIconDark from 'assets/icons/Settings-dark.png'
import SettingsIconMobileActive from 'assets/icons/settings-Filled.png'
import Settings from "ui/pages/settings"



export const Routes: RoutesInterface[] = [
    {
        title: 'MySkills',
        link: '/home',
        icon: <ImageComponent src={MySkillsIcon} alt="mySkills" />,
        iconDark: <ImageComponent src={MySkillsActiveIcon} alt="mySkills"/>,
        iconMobile: <ImageComponent src={MySkillsIcon} alt="mySkills" />,
        iconActiveMobile: <ImageComponent src={MySkillsActiveIcon} alt="mySkills" />,
        component: (
            <Home />
        ),
    },
    {
        title: 'Skillary',
        link: '/library',
        icon: <ImageComponent src={SkillaryIcon} alt="skillary" />,
        iconDark: <ImageComponent src={SkillaryActiveIcon} alt="skillary" />,
        iconMobile: <ImageComponent src={SkillaryIcon} alt="skillary" />,
        iconActiveMobile: <ImageComponent src={SkillaryActiveIcon} alt="skillary" />,
        component: (
            <SelectedSkillContextProvider>
                <Library />
            </SelectedSkillContextProvider>
        ),
    },
    {
        title: 'Skillboard',
        link: '/skills',
        icon: <ImageComponent src={SkillboardIcon} alt="skillboard" />,
        iconDark: <ImageComponent src={SkillboardActiveIcon} alt="skillboard" />,
        iconMobile: <ImageComponent src={SkillboardIcon} alt="skillboard" />,
        iconActiveMobile: <ImageComponent src={SkillboardActiveIcon} alt="skillboard" />,
        component: (
            <Skills />
        ),
    },

    {
        title: 'Skillbox',
        link: '/skillbox',
        icon: <ImageComponent src={SkillboxIcon} alt="skillbox" />,
        iconDark: <ImageComponent src={SkillboxActiveIcon} alt="skillbox" />,
        iconMobile: <ImageComponent src={SkillboxIcon} alt="skillbox" />,
        iconActiveMobile: <ImageComponent src={SkillboxActiveIcon} alt="skillbox" />,
        component: (
            <Skillbox/>
        ),
    },
    {
        title: 'Skillcards',
        link: '/Skillcards',
        icon: <ImageComponent src={SkillcardIcon} alt="Skillcard" />,
        iconDark: <ImageComponent src={SkillcardActiveIcon} alt="Skillcard" />,
        iconMobile: <ImageComponent src={SkillcardIcon} alt="Skillcard" />,
        iconActiveMobile: <ImageComponent src={SkillcardActiveIcon} alt="Skillcard" />,
        component: (
            <Skillcards/>
        ),
    },

    {
        title: 'Settings',
        link: '/settings',
        icon: <ImageComponent src={SettingsIconD} alt="settings" />,
        iconDark: <ImageComponent src={SettingsIconDark} alt="settings" />,
        iconMobile: <ImageComponent src={SettingsIcon} alt="settings" />,
        iconActiveMobile: <ImageComponent src={SettingsIconMobileActive} alt="settings" />,
        component: (
            <Settings/>
        ),
    },
]