import React, { useContext } from "react";
import { Drawer, List, ListItem, ListItemButton, Typography, styled, useTheme } from "@mui/material";
import { Routes } from "entities/routes"; 
import { useNavigate, useLocation } from "react-router-dom";
import { TextSizeContext } from "data/index";
import { useTranslation } from "react-i18next";
import { TextSizes } from "entities/constants";

// Entferne "Settings" aus der Sidebar, es bleibt aber in Routes bestehen
const SidebarMenu = Routes.filter(route => route.title !== "Settings");

const SidebarContainer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.background.default,
    borderRight: "1px solid rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "6rem",
    height: "100vh",
    paddingTop: "1rem",
    overflow: "hidden",
    zIndex: "999",
    position: "fixed",
    left: 0,
    top: 0,
  },
}));

const ListElement = styled(ListItem)<{ active?: string }>(({ theme, active }) => ({
  padding: "0.5rem 0",
  background: active ? theme.palette.primary.light : "none",
  borderRadius: "0px 5.5rem 5.5rem 0px",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    transform: "scale(1.05)",
  },
}));

const ListButton = styled(ListItemButton)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "6rem", 
  width: "6rem",
  padding: "1rem 0",
  borderRadius: "0px 5.5rem 5.5rem 0px",
}));

const ListButtonText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.primary,
  textAlign: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "100%",
  fontSize: "0.85rem",
  marginTop: "0.5rem",
}));

const Sidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const active = pathname.split('/')[1];
  const { state: textSize } = useContext(TextSizeContext);
  const { t } = useTranslation();

  return (
    <SidebarContainer variant="permanent">
      <List sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>  
        {SidebarMenu.map(menu => (
          <ListElement key={menu.title} active={menu.link === `/${active}` ? 'true' : undefined}>
            <ListButton onClick={() => navigate(menu.link)}>
              {menu.icon}
              <ListButtonText>{t(menu.title)}</ListButtonText>
            </ListButton>
          </ListElement>
        ))}
      </List>
    </SidebarContainer>
  );
}

export default Sidebar;
